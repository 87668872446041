<script setup lang="ts">
  import { normalizeStyle, computed } from 'vue';

  export interface IProps {
    animationName?: string | boolean;
    h?: string;
    w?: string;
    gap?: string;
    radius?: string;
    rep?: number;
    chunkClass?: string;
    fill?: boolean;
    maxWidth?: string;
    minWidth?: string;
    isCenter?: boolean;
    direction?: 'column' | 'row';
    size?: 'large' | 'medium' | 'small';
  }

  const props = withDefaults(defineProps<IProps>(), {
    animationName: 'wave',
    w: '100%',
    gap: '0.5rem',
    radius: '0.5rem',
    rep: 1,
    fill: true,
    isCenter: false,
    direction: 'row',
    size: 'large',
  });

  const getHeight = () => {
    switch (props.size) {
      case 'large':
        return '3.5rem';
      case 'medium':
        return '3rem';
      case 'small':
        return '2rem';
    }
  };

  const height = computed(() => {
    return props.h || getHeight();
  });

  const maxWidth = computed(() =>
    props.fill && props.maxWidth ? `${(+props.w.slice(0, -2) * 100) / +props.maxWidth.slice(0, -2)}%` : props.w,
  );
</script>

<template>
  <span
    :class="[
      'v-skeleton-loading',
      {
        'v-skeleton-loading__column': props.direction === 'column',
      },
    ]"
    :style="
      normalizeStyle({
        maxWidth: props.maxWidth,
        justifyContent: props.isCenter ? 'center' : undefined,
        gap: props.gap,
      })
    "
  >
    <span
      v-for="index in props.rep"
      :key="index"
      :class="[
        props.chunkClass,
        'v-skeleton-loading--chunk',
        {
          [`v-skeleton-loading--chunk__${props.animationName}`]: !!props.animationName,
        },
      ]"
      :style="
        normalizeStyle({
          height,
          width: props.fill ? '100%' : props.w,
          maxWidth,
          minWidth: props.minWidth,
          borderRadius: props.radius,
        })
      "
    >
    </span>
  </span>
</template>

<style lang="scss">
  .v-skeleton-loading {
    display: flex;
    width: 100%;
    flex: 0 1 auto;
    flex-direction: row;
    &__column {
      flex-direction: column;
    }

    &--chunk {
      color: transparent;
      display: block;
      user-select: none;
      background: rgb(255 248 248 / 7%);

      * {
        visibility: hidden;
      }

      &__wave {
        position: relative;
        overflow: hidden;
        &::after {
          animation: wave 1.5s linear 0s infinite;
          background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
          content: '';
          position: absolute;
          transform: translate3d(-100%, 0, 0);
          will-change: transform;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }
  }

  @keyframes wave {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    60% {
      transform: translate3d(100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
</style>
